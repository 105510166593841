
.leftPanel {
    margin: 10px 5px;
}

.pageFlagModal {
    color: #808080;
    margin:16px 0px;
    font-size: 14px;
    min-width: 90px;

    .heading {
        margin-left: 16px;
        margin-bottom:12px;
        padding-right: 4px;
    }
}

.pageLeftOffIcon {
    float: right;
    margin-right:12px;
}

.MuiList-padding{
    padding: 4px !important;
}

.ministryCodeModal {
    margin:16px 0px;
    font-size: 14px;
    max-width: 150px;
    max-height: 450px;
}

.btnenabled {
    border: none;
    background-color: #003366;
    color: #FFFFFF;
}

.leftPanelIcons{
    color: #808080 !important;
    font-size: 14px !important;
}


.other-pb-modal-message {
    margin: 10px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
        flex: 1;
    }
}

.add-consult-ministry {
    background-color: #003366;
    margin-left: 20px;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: white;
    border: none;
    > div {
        font-size: 36px;
        margin-top: -9px;
    }
}

.MuiDialogActions-root {
    padding: 20px 20px 30px !important;
    justify-content: center !important;
    flex-direction: row;
}

#state-change-dialog .MuiDialogContent-root, #state-change-dialog .MuiDialogActions-root {
    padding: 20px 100px !important;
}

#state-change-dialog-title .MuiIconButton-root {
    background-color: #003366 ;
    color: white;
    border-radius: unset;
}
#state-change-content {
    padding: 15px 50px 50px 50px;
}

#consult-modal-description {
    display: flex;
    justify-content: center;
    color: black; 
    flex-direction: column;
    text-align: center;
}

.consult-modal-margin {
    margin: 0px 20px !important;
}

.consult-modal-ministries-list {
    text-align: left;
    margin: 10px;

    .check-item {
        position: relative;
        margin: 0 1.6%;
        padding: 0;
        width: 13%;
        padding-left: min(3.5%, 30px);
        font-size: 14px;
    }

    .check-item.other-ministry {        
        width: 29.2%;
    }
    
    .checkmark{
        position:absolute;
        top:3px;
        left:0;
        
        height:14px;
        width:14px;
        // background-color:#eee;
        border-radius: 3px;
        border: 1px solid;
    }

    .checkmark:checked{
        accent-color: #178100;
    }
}



.btn-bottom {
    max-width: 50%;
}

.MuiDialogActions-spacing {
    margin-left: 3% !important;
    margin-right: 3% !important;
}

.MuiFormLabel-root{
    line-height: 1;
    letter-spacing: 0.00938em;
    font-family: "BCSans-Bold", sans-serif !important;
    color: black !important; 
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid !important;     
}

.MuiMenuItem-root {
    font-size: 14px !important;
    font-family: "BCSans-Regular", sans-serif !important;
    overflow-wrap: anywhere;
    white-space: break-spaces !important;
}
.PrivateNotchedOutline-legendLabelled-4 {
    font-size: 0.80rem !important;
}

.style-float{
    float: right;
}

.hrStyle {
    margin: 10px 0px;
}

.pageLeftOff {
    margin-left: 16px;
    cursor: pointer;
}

.MuiTreeItem-label {
    //max-width:20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 3px 0px;
}

.MuiTreeItem-iconContainer {
    color: #808080;
}

.MuiTreeItem-content.Mui-selected {
    background-color: rgba(25, 118, 210, 0.4) !important;
}

.MuiTreeItem-content:hover {
    background-color: rgba(25, 118, 210, 0.2) !important
}

.filterIcons {
    color: #808080;
    margin-right: 10px;
    vertical-align: middle;
    font-size: 20px;
    cursor: pointer;

    &:active{
        color: #003366;
    }
    &:focus{
        color: #003366;
    }
}

.filterConsultIcon{
    color: #808080;
    vertical-align: middle;
    font-size: 20px;
    cursor: pointer;

    &:active{
        color: #003366;
    }
    &:focus{
        color: #003366;
    }
}

.filterDropDownIcon{
    color: #808080;
    vertical-align: bottom;
    font-size: 13px;
    cursor: pointer;
    margin: 5px;

    &:active{
        color: #003366;
    }
    &:focus{
        color: #003366;
    }
}

.consulteeItem {
    margin: 10px;
}

.filterText{
    margin-right: 10px;
}

.otherOption {
    margin: 6px 16px;
    padding-bottom:16px;
}

.bookmark-text-align {
    text-align: center;
}

.selected {
    color: #003366;
}

.counters{
    color: #808080;
    font-size: 14px !important;
}

.consultDropDown{
    width: 150px;
    margin: 16px 0px;
    font-size: 14px;

    .heading {
        margin-bottom:12px;
    }
}
.MuiTreeItem-content {
    .MuiTreeItem-iconContainer {
        justify-content: right !important;
        gap: 2px !important;
    }
}

.pluscircle {
  margin: 0px 8px 8px 0px;
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 24px;
}
.taglist-cfdpersonal {
  margin: 10px 9% 15px 9%;
}
.taglist {
  margin: 15px 9% 25px 9%;
}
.tagtitle {
  margin-left: 9%;
  margin-top: 5px;
}
.tag-message-container {
  margin: 5px 0px 0px 9%;
  display: block;
  p {
    line-height: 1rem;
    font-size: 12px;
  }
}
.tag-message-container-scanning {
  margin: 5px 0px 20px 9%;
  display: block;
  p {
    line-height: 1rem;
    font-size: 12px;
  }
  padding-right: 10px;
}
.search-grid-container {
  display: inline-block !important;
  margin: 20px 0 !important;
}
.search-grid {
  // border-right: 2px solid #38598A;
  background-color: rgba(56,89,138,0.1);
}
.search-icon {
  color: #38598A;
}
.editPersonalTags {
    padding: 4px 0px;
    cursor: pointer;
}
.editPersonalTags:hover {
    background-color: #f2f2f2;
}
.editPersonalTagsDisabled {
    padding: 4px 0px;
    cursor: not-allowed;
    color: #cfcfcf;
}