@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: "BCSans-Regular";
    src: url("../public/FOI/webfonts/BCSans-Regular.woff") format('woff');
}

@font-face {
    font-family: "BCSans-Bold";
    src: url("../public/FOI/webfonts/BCSans-Bold.woff") format('woff');
}

body {
    font-size: 16px;
    font-family: 'Lato,sans-serif', sans-serif !important;
}

div {
    font-family: 'Lato,sans-serif', sans-serif !important;
}

.btn-primary {
    background-color: #014283;
    border-color: #00152a;
}

.app-header {
    background-color: #f1f1f1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: #036;
}