nav {
    background-color: #036 !important;
    color: #fff;
}

.navicons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 30px 2px 0px;
}

.foiHeader {
    .container {
        height: 40px;
    }

    .signout-btn {
        font-size: 12px;
        background-color: #036 !important;
        width: 100%;
        height: 100%;
        font-family: 'BCSans-Bold', sans-serif !important;
        color: #FFFF;
        border-color: #FFFF !important;
        position: relative;
        max-height: 40px;
    }

    .foiNavItem {
        padding-right: 30px;
        color: #FFFF;
    }
}